import { createSlice } from '@reduxjs/toolkit';

import type { TSepaOrderStateSlice } from './types';
import { fetchSepaOrderStateThunk, fetchStartSepaOrderThunk } from './thunks';

const defaultState: TSepaOrderStateSlice = {
  sepaOrderState: null,
  sepaOrderStateError: null,
};

const slice = createSlice({
  name: 'sepaOrderState',
  initialState: defaultState,
  reducers: {},
  extraReducers: (builder) => {
    // fetchSepaOrderStateThunk
    builder.addCase(
      fetchSepaOrderStateThunk.fulfilled,
      (draftState, { payload }) => {
        draftState.sepaOrderState = payload;
      },
    );

    builder.addCase(
      fetchSepaOrderStateThunk.rejected,
      (draftState, { payload }) => {
        draftState.sepaOrderStateError = null;

        if (payload) {
          draftState.sepaOrderStateError = payload;
        }
      },
    );

    // fetchStartSepaOrderThunk
    builder.addCase(
      fetchStartSepaOrderThunk.fulfilled,
      (draftState, { payload }) => {
        draftState.sepaOrderState = payload;
      },
    );

    builder.addCase(
      fetchStartSepaOrderThunk.rejected,
      (draftState, { payload }) => {
        draftState.sepaOrderStateError = null;

        if (payload) {
          draftState.sepaOrderStateError = payload;
        }
      },
    );
  },
});

export default slice;
