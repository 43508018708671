import { ExternalPubHttpApiProcessingSendPaymentTokenRequest } from '@acme/external-processing-api-client/dist/types';

import apiClient from '../client';
import { TWithoutRequestId } from '../types';

type TSetPaymentTokenRequest =
  TWithoutRequestId<ExternalPubHttpApiProcessingSendPaymentTokenRequest>;

export default async function setPaymentToken(data: TSetPaymentTokenRequest) {
  return apiClient.processingSendPaymentToken(data);
}
