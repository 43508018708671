import { PropsWithChildren } from 'react';
import Snackbar from 'src/components/Snackbar';
import ChangeLanguage from 'src/components/ChangeLanguage';
import Container from 'src/components/Container';
import Footer from 'src/components/Footer';

import styles from './Layout.module.scss';

export const Layout = ({ children }: PropsWithChildren<unknown>) => (
  <div className={styles.container}>
    <div className={styles.topContent}>
      <header className={styles.header}>
        <Container className={styles.headerContainer}>
          <div className={styles.logoContainer}>
            <div className={styles.logo} />
          </div>

          <ChangeLanguage fixedLanguage='ru' />
        </Container>
      </header>

      <main className={styles.content}>
        <Container className={styles.contentContainer}>
          {children}
        </Container>
      </main>
    </div>

    <Snackbar />
  </div>
);
