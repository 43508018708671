class PaymentMethodError<ErrorCode extends string = string> extends Error {
  constructor(code: ErrorCode) {
    super(code);

    this.name = 'PaymentMethodError';
  }

  toString() {
    return this.message as ErrorCode;
  }
}

export default PaymentMethodError;
