import config from 'src/config';

import { stdoutSendLog } from './StdoutLogger';
import { SentryLogger } from './SentryLogger';

const logger = new SentryLogger();

export const initLogger = () => {
  SentryLogger.initSentry(config.sentryDSN);
};

export const setUserId = (userId: string | null) => {
  logger.setUser(userId);
};

export const logError = (e: Error, extraData?: Record<string, unknown>) => {
  if (config.environment === 'development') {
    // eslint-disable-next-line no-console
    console.log(e, extraData);
  } else {
    logger.logException(e, extraData);
  }

  stdoutSendLog({ [e.message]: extraData });
};

export const logMessage = (
  message: string,
  extraData?: Record<string, unknown>,
) => {
  if (config.environment === 'development') {
    // eslint-disable-next-line no-console
    console.log(message, extraData);
  } else {
    logger.logMessage(message, extraData);
  }

  stdoutSendLog({ [message]: extraData });
};

export const logBreadcrumb = (
  message: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data?: Record<string, any>,
) => {
  logger.addBreadcrumb(message, data);
};
