import apiClient from '../client';

export default async function downloadSepaPdf(
  orderId: string,
  customerIp?: string,
) {
  return apiClient.processingSepaDownloadPdfFileWithOrder({
    order_id: orderId,
    customer_ip: customerIp,
  });
}
