import { encrypt, message, key } from 'openpgp';

export async function encode(
  data: Record<string, boolean | number | string | null>,
  publicKey: string,
): Promise<string> {
  const cardString = JSON.stringify(data);

  const result = await encrypt({
    message: message.fromText(cardString),
    publicKeys: (await key.readArmored(publicKey)).keys,
  });

  return result.data;
}
