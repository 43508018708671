export const ORDER_DETAILS_FIELDS = [
  'fiat_currency_amount',
  'cryptocurrency_amount',
  'fiat_currency',
  'cryptocurrency',
  'cryptocurrency_payout_address',
  'email',
  'phone',
  'acceptTerms',
  'birthday',
] as const;

type FieldType = {
  value: string;
  disabled: boolean;
  required: boolean;
};

export type OrderDetailsFormData = Partial<
  Record<typeof ORDER_DETAILS_FIELDS[number], FieldType>
>;
