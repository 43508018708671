import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { TitleProps } from 'src/models/PaymentMethod';

import styles from './BankCardTitle.module.scss';

function BankCardTitle({ orderState, className, ...props }: TitleProps) {
  const { t } = useTranslation();

  return (
    <div className={clsx(styles.container, className)} {...props}>
      {orderState.supported_payment_systems.includes('visa') && <div className={styles.visaIcon} title="Visa" />}
      {orderState.supported_payment_systems.includes('mastercard') && <div className={styles.mastercardIcon} title="Mastercard" />}
      {orderState.supported_payment_systems.includes('maestro') && <div className={styles.maestroIcon} title="Maestro" />}
      {orderState.supported_payment_systems.includes('mir') && <div className={styles.mirIcon} title="Mir" />}

      {t('gift.paymentCurrencySections.fiat')}
    </div>
  );
}

export default BankCardTitle;
