import { combineReducers } from '@reduxjs/toolkit';

import globalLoading from './modules/globalLoading/reducer';
import ordersInfo from './modules/ordersInfo/reducer';
import orderState from './modules/orderState/reducer';
import sepaOrderState from './modules/sepaOrderState/reducer';
import snackbar from './modules/snackbar/reducer';

export default combineReducers({
  globalLoading,
  ordersInfo,
  orderState,
  sepaOrderState,
  snackbar,
});
