import { useTranslation } from 'react-i18next';
import useSepaOrderState from 'src/hooks/useSepaOrderState';

import Requisite from '../Requisite';

const BankRequisites = () => {
  const { t } = useTranslation();
  const { sepaOrderState } = useSepaOrderState();

  if (!sepaOrderState) {
    return null;
  }

  const { bank_requisite } = sepaOrderState;
  const { name, swift_code, country } = bank_requisite;

  return (
    <>
      <Requisite label={t('sepa.requisite.bank_name')} value={name} />
      <Requisite
        label={t('sepa.requisite.bank_swift_code')}
        value={swift_code}
      />
      <Requisite label={t('sepa.requisite.bank_country')} value={country} />
    </>
  );
};

export default BankRequisites;
