import { useCallback } from 'react';
import * as actions from 'src/redux/modules/globalLoading/actions';

import { useAppDispatch, useAppSelector } from './redux';

function useGlobalLoading() {
  const dispatch = useAppDispatch();

  const globalLoading = useAppSelector((state) => state.globalLoading.loading);

  const setGlobalLoading = useCallback((loading: boolean) => {
    dispatch(actions.setLoading(loading));
  }, []);

  return { globalLoading, setGlobalLoading };
}

export default useGlobalLoading;
