import { ExternalPubHttpApiProcessingProcessPaymentRequest } from '@acme/external-processing-api-client/dist/types';
import { ProcessingSecure3dData } from '@acme/ownr-spec';

import apiClient from '../client';
import { TWithoutRequestId } from '../types';

type TAuthorizeRequest =
  TWithoutRequestId<ExternalPubHttpApiProcessingProcessPaymentRequest>;

export function build3dSecureData(): ProcessingSecure3dData {
  return {
    browser_color_depth: window.screen.colorDepth,
    browser_language: window.navigator.language,
    browser_screen_height: window.screen.height,
    browser_screen_width: window.screen.width,
    browser_timezone: new Date().getTimezoneOffset(),
    browser_user_agent: window.navigator.userAgent,
    browser_java_enabled: window.navigator.javaEnabled(),
    window_height: window.outerWidth || window.screen.width,
    window_width: window.outerHeight || window.screen.height,
  };
}

export default async function authorize(request: TAuthorizeRequest) {
  const response = await apiClient.processingProcessPayment(request);

  if (response.status !== 'OK') {
    throw new Error(response.error_code);
  }

  return response.payment_form_state;
}
