import { Collapse, Radio, Typography } from '@acme/web-ui-kit';
import clsx from 'clsx';
import { useId } from 'react';

import { Props } from './PaymentMethod.types';
import styles from './PaymentMethod.module.scss';

function PaymentMethod ({ active, title, className, children, onActivate, disabled = false, ...props }: Props) {
  const id = useId();

  return (
    <div className={clsx(styles.container, { [styles.containerActive]: active }, className)} {...props}>
      <div className={styles.header} role="none" onClick={() => document.getElementById(id)?.click()}>
        <Typography variant="H5Normal" className={styles.title}>{title}</Typography>

        <div>
          <Radio
            id={id}
            checked={active}
            label={null}
            className={clsx(styles.radio, { [styles.radioDisabled]: disabled })}
            onChange={(event) => {
              if (event.target.checked) {
                onActivate();
              }
            }}
          />
        </div>
      </div>

      <Collapse visible={active}>
        {children}
      </Collapse>
    </div>
  );
}

export default PaymentMethod;
