export const cardInputs = [
  {
    name: 'pan',
    maxLength: 19,
  },
  {
    name: 'expiration_date',
    maxLength: 7,
  },
  {
    name: 'cvv',
    maxLength: 3,
  },
  {
    name: 'holder',
  },
];

export const formDefinitionI18nMap: Partial<Record<string, string>> = {
  cryptocurrency_payout_address: 'receivingAddress',
  fiat_currency_amount: 'paymentAmount',
  cryptocurrency_amount: 'purchaseAmount',
  birthday: 'birthday',
};

export function formatField (name: string, value: string) {
  if (name === 'phone') {
    return value.replace(/[^\d+]/g, '').slice(0, 15);
  }

  return value
}
