import { TProps } from './RenderItem.types';
import styles from './styles.module.scss';

const RenderItem = ({ item, onChange }: TProps) => {
  const onClick = () => {
    onChange(item.value);
  };

  return (
    <button type="button" className={styles.button} onClick={onClick}>
      {item.label}
    </button>
  );
};

export default RenderItem;
