import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { v3 } from '@acme/web-ui-kit';
import { parseDefaultLanguage } from 'src/i18n/parseDefaultLanguage';
import { languageList } from 'src/i18n/constants';
import { changeLanguage } from 'src/i18n/utils';

import { FLAG_ICONS } from './ChangeLanguage.constants';
import { Props } from './ChangeLanguage.types';
import styles from './styles.module.scss';

const LANGUAGE_LIST = languageList.map((language) => ({
  value: language[0],
  label: (
    <div className={styles.label}>
      {/* @ts-expect-error languages */}
      <img src={FLAG_ICONS[language[1]]} alt={language[0]} className={styles.icon} /> {language[1]}
    </div>
  ),
}));

const ChangeLanguage = ({ colorScheme, fixedLanguage, ...props }: Props) => {
  const { i18n } = useTranslation();
  const [language, setLanguage] = useState(
    fixedLanguage ?? parseDefaultLanguage(i18n.language),
  );

  const onChangeLanguage = useCallback(
    (value: string) => {
      setLanguage(value);
    },
    [],
  );

  useEffect(() => {
    changeLanguage(language);
    i18n.changeLanguage(language);
  }, [language, i18n]);

  if (fixedLanguage) {
    return null;
  }

  return (
    <div>
      <v3.Select
        variant="text"
        colorScheme={colorScheme}
        value={language}
        data={LANGUAGE_LIST}
        className={styles.container}
        onChange={onChangeLanguage}
      />
    </div>
  );
};

export default ChangeLanguage;
