import { useEffect } from 'react';

const Redirect = () => {
  useEffect(() => {
    window.location.replace('https://portal.ownrwallet.com/cryptoshop/orders');
  });

  return null;
};

export default Redirect;
