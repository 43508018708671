import { CountDownTimer, v4 } from '@acme/web-ui-kit';

import { Props } from './Header.types';
import styles from './Header.module.scss';

function Header ({ title, expiresAt }: Props) {
  return <div className={styles.container}>
    <v4.Typography variant="H1/700" className={styles.title}>{title}</v4.Typography>

    {expiresAt ? (
      <div className={styles.timer}>
        <v4.Icon iconName="Status_Pending" className={styles.timerIcon} />

        <v4.Typography variant="H4/400" className={styles.timerText}>
          <CountDownTimer expireAt={expiresAt} />
        </v4.Typography>
      </div>
    ) : null}
  </div>
}

export default Header;
