import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Typography } from '@acme/web-ui-kit';
import Logo from 'src/assets/ownr-logo.svg';
import OrderExpiredIcon from 'src/assets/order-expired.svg';
import useSepaOrderState from 'src/hooks/useSepaOrderState';
import FullPageLoader from 'src/components/FullPageLoader';
import Snackbar from 'src/components/Snackbar';

import ChangeLanguage from './ChangeLanguage';
import styles from './styles.module.scss';
import UserRequisites from './UserRequisites';
import OrderDetails from './OrderDetails';
import OwnrRequisites from './OwnrRequisites';
import BankRequisites from './BankRequisites';
import Actions from './Actions';

const Sepa = () => {
  const { t } = useTranslation();
  const { orderId } = useParams();
  const { sepaOrderState, fetchSepaOrderState } = useSepaOrderState();

  useEffect(() => {
    if (orderId && !sepaOrderState) {
      fetchSepaOrderState(orderId);
    }
  }, [orderId, sepaOrderState, fetchSepaOrderState]);

  if (!sepaOrderState) {
    return <FullPageLoader />;
  }

  return (
    <div className={styles.root}>
      <header className={styles.header}>
        <img className={styles.headerLogo} src={Logo} alt="" />
        <ChangeLanguage />
      </header>

      <main className={styles.main}>
        <div className={styles.invoiceDetails}>
          <Typography variant="H3Light" className={styles.invoiceDetailLabel}>
            {t('sepa.invoice_number')}
          </Typography>
          <Typography variant="H3Normal">
            {`#${sepaOrderState.invoice_number}`}
          </Typography>
        </div>

        {sepaOrderState.status !== 'expired' ? <UserRequisites /> : null}

        <div className={styles.divider} />

        <OrderDetails />

        <div className={styles.divider} />

        {sepaOrderState.status === 'expired' ? (
          <>
            <div className={styles.orderExpiredMessageContainer}>
              <img src={OrderExpiredIcon} alt="" />
              <Typography
                variant="H4Normal"
                className={styles.orderExpiredMessage}
              >
                {t('sepa.order_has_been_expired')}
              </Typography>
            </div>
            <div className={styles.divider} />
          </>
        ) : null}

        {sepaOrderState.status !== 'expired' ? (
          <>
            <Typography
              variant="H4Normal"
              className={styles.paymentDetailsHeader}
            >
              {t('sepa.make_all_payments_to')}
            </Typography>
            <OwnrRequisites />
            <BankRequisites />
            <Typography
              variant="BodyNormal"
              className={styles.paymentDetailsAdditionalInfo}
            >
              {t('sepa.payment_details_additional_info')}
            </Typography>
          </>
        ) : null}

        <Actions />
      </main>

      <footer className={styles.footer}>
        <div className={styles.divider} />
        <div className={styles.footerLicense}>
          <img className={styles.footerLogo} src={Logo} alt="" />
          <Typography variant="CaptionSmall">
            OWNR WALLET OÜ (registration code - 14555701) is registered at Harju
            maakond, Tallinn, Kesklinna linnaosa, Väike-Ameerika tn 8-421,
            10129, and holds the license FVT000406 for providing services of
            exchanging a virtual currency against a fiat currency and providing
            a virtual currency wallet service.
          </Typography>
        </div>
      </footer>

      <Snackbar />
    </div>
  );
};

export default Sepa;
