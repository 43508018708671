import { useMemo } from 'react';
import { capitalize } from 'lodash';
import { v3, v4 } from '@acme/web-ui-kit';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import NumberFormat from 'react-number-format';
import { ComponentProps } from 'src/models/PaymentMethod';
import { getCardSystem, isValidCVV, isValidExpiryDate, isValidPAN } from 'src/utils/card';

import { BankCardPaymentData } from './BankCard.types';
// eslint-disable-next-line css-modules/no-unused-class
import styles from './BankCard.module.scss';

function BankCard({ data, onDataChange, orderState, errors, className, ...props }: ComponentProps<BankCardPaymentData>) {
  const { t } = useTranslation();

  const panError = useMemo(() => {
    if (data.pan) {
      const cardSystem = getCardSystem(data.pan);

      if (!isValidPAN(data.pan) || !cardSystem) {
        return t('payform.pan_error');
      }

      if (!(orderState.supported_payment_systems as Array<string>).includes(cardSystem.toLocaleLowerCase())) {
        return t('errors.UNSUPPORTED_PAYMENT_METHOD');
      }
    }

    return null;
  }, [data.pan, orderState.supported_payment_systems, t]);

  const cardType = useMemo(() => {
    if (data.pan) {
      const type = getCardSystem(data.pan);

      if (type && ['masterCard', 'visa', 'maestro', 'mir'].includes(type)) {
        return type.toLocaleLowerCase() as Lowercase<typeof type>;
      }
    }

    return 'none';
  }, [data.pan]);

  return (
    <div className={clsx(styles.container, className)} {...props}>
      <div className={styles.cardContainer}>
        <div className={styles.panInputContainer}>
          <NumberFormat<v3.TInputProps>
            customInput={v3.Input}
            required
            format="#### #### #### ####"
            name="pan"
            label={t('payform.card.pan')}
            placeholder="#### #### #### ####"
            isPersistentPlaceholder
            type="tel"
            colorScheme="dark"
            className={styles.panInput}
            value={data.pan}
            isError={!!panError}
            onChange={(event) => {
              const { value } = event.target;

              if (isValidPAN(value) && value.replace(/\s/g, '').length === 16) {
                document.getElementsByName('expiryDate')[0]?.focus();
              }

              onDataChange({
                ...data,
                pan: value,
              });
            }}
          />

          {panError ? <v4.Typography variant="Caption/400" className={styles.error}>{panError}</v4.Typography> : null}
        </div>

        <NumberFormat<v3.TInputProps>
          customInput={v3.Input}
          required
          format="## / ##"
          name="expiryDate"
          isPersistentPlaceholder
          placeholder="MM/YY"
          type="tel"
          colorScheme="dark"
          className={styles.expiryDateInput}
          isError={!!data.expiryDate && !isValidExpiryDate(data.expiryDate)}
          value={data.expiryDate}
          onChange={(event) => {
            const { value } = event.target;

            if (isValidExpiryDate(value)) {
              document.getElementsByName('cvv')[0]?.focus();
            }

            onDataChange({
              ...data,
              expiryDate: value,
            });
          }}
        />

        <NumberFormat<v3.TInputProps>
          customInput={v3.Input}
          required
          name="cvv"
          maxLength={3}
          isPersistentPlaceholder
          placeholder="CVV"
          type="tel"
          colorScheme="dark"
          value={data.cvv}
          isError={!!data.cvv && !isValidCVV(data.cvv)}
          rightComponent={<div className={styles[`card${capitalize(cardType) as Capitalize<typeof cardType>}`]} />}
          className={styles.cvvInput}
          onChange={(event) => {
            const { value } = event.target;

            if (isValidCVV(value)) {
              document.getElementsByName('holder')[0]?.focus();
            }

            onDataChange({
              ...data,
              cvv: value,
            });
          }}
        />
      </div>

      <div className={styles.holderNameContainer}>
        <v3.Input
          required
          name="holder"
          label={t('payform.card.holder')}
          placeholder="Cardholder name"
          isPersistentPlaceholder
          type="text"
          value={data.holderName}
          colorScheme="dark"
          className={styles.holderNameInput}
          onChange={(event) => {
            const { value } = event.target;

            onDataChange({
              ...data,
              holderName: value.replace(/[^\sA-Za-z]/g, ''),
            });
          }}
        />
      </div>
    </div>
  );
}

export default BankCard;
