import OwnrI18n from '@acme/ownr-i18n';
import { typedObjKeys } from 'src/utils/general';

export const i18nextResources = Object.fromEntries(
  typedObjKeys(OwnrI18n.translations).map((key) => [
    key,
    {
      translation: OwnrI18n.translations[key],
    },
  ]),
);

export const languageList = typedObjKeys(i18nextResources).map((key) => [
  // @ts-expect-error
  i18nextResources[key].translation.isoCode as string,
  // @ts-expect-error
  (i18nextResources[key].translation.isoCode as string)
    .split('-')[0]
    .toLocaleUpperCase(),
]);
