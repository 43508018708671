import { createSlice } from '@reduxjs/toolkit';

import type { TOrdersInfo } from './types';
import { fetchPaymentDataThunk } from './thunks';

const defaultState: TOrdersInfo = {
  publicKey: null,
  orderInfoError: null,
  fetching: false,
};

const slice = createSlice({
  name: 'orderInfo',
  initialState: defaultState,
  reducers: {
    resetOrderInfo: (draftState) => {
      draftState.publicKey = null;
      draftState.orderInfoError = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchPaymentDataThunk.pending, (draftState) => {
      draftState.fetching = true;
    });
    builder.addCase(
      fetchPaymentDataThunk.fulfilled,
      (draftState, { payload }) => {
        draftState.publicKey = payload;
        draftState.orderInfoError = null;
        draftState.fetching = false;
      },
    );
    builder.addCase(
      fetchPaymentDataThunk.rejected,
      (draftState, { payload }) => {
        draftState.orderInfoError = null;
        draftState.fetching = false;

        if (payload) {
          draftState.orderInfoError = payload;
        }
      },
    );
  },
});

export default slice;
