import { languageList } from './constants';

export const parseDefaultLanguage = (language: string) => {
  const languageFromList = languageList.find((item) => item[0].includes(language))

  if (languageFromList) {
    return languageFromList[0];
  }

  return 'en-US';
};
