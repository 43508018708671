import PaymentMethodError from 'src/models/PaymentMethodError';
import PaymentMethod from 'src/models/PaymentMethod';
import { ProcessingPaymentFormState } from '@acme/external-processing-api-client/dist/types';
import { OrderState } from 'src/types/order';
import config from 'src/config';
import { setPaymentToken } from 'src/api';
import { logError } from 'src/services/logger';

import { OrderDetailsFormData } from '../../Order.types';

import GooglePayComponent from './GooglePay.component';
import GooglePayTitleComponent from './GooglePayTitle.component';
import { GooglePayPaymentData } from './GooglePay.types';

function buildSupportedPaymentMethodData ({
  amount,
  currency,
  orderState,
}: {
  amount: string;
  currency: string;
  orderState?: OrderState;
  }) {
  return [
    {
      supportedMethods: 'https://google.com/pay',
      data: {
        environment: config.environment === 'production'
          ? 'PRODUCTION'
          : 'TEST',
        apiVersion: 2,
        apiVersionMinor: 0,
        allowedPaymentMethods: [
          {
            type: 'CARD',
            parameters: {
              allowedAuthMethods: ['CRYPTOGRAM_3DS'],
              allowedCardNetworks: (['MASTERCARD', 'VISA'] as const).filter((item) => !orderState || orderState?.supported_payment_systems.includes(item.toLocaleLowerCase() as Lowercase<typeof item>)),
            },
            tokenizationSpecification: {
              type: 'PAYMENT_GATEWAY',
              parameters: {
                gateway: config.gateway,
                gatewayMerchantId: config.gatewayMerchantId,
              },
            },
          },
        ],
        merchantInfo: {
          merchantId: config.merchantId,
          merchantName: config.merchantName,
        },
        transactionInfo: {
          totalPriceStatus: 'FINAL',
          totalPriceLabel: 'Total',
          totalPrice: amount,
          currencyCode: currency,
        },
      },
    },
  ];
}

function buildShoppingCartDetails ({
  amount,
  currency,
}: {
  amount: string;
  currency: string;
}) {
  return {
    id: 'ownr',
    displayItems: [
      {
        label: 'Item',
        amount: { currency, value: amount }
      }
    ],
    total: {
      label: 'Total',
      amount: { currency, value: amount }
    }
  };
}

class GooglePay extends PaymentMethod<'googlePay', GooglePayPaymentData> {
  initialData: GooglePayPaymentData = {
    name: 'googlePay',
  };

  paymentTypes = ['googlepay'];

  Component = GooglePayComponent;

  TitleComponent = GooglePayTitleComponent;

  public constructor (onUpdate: () => void, orderState: OrderState) {
    super(onUpdate, orderState);

    try {
      const request = new PaymentRequest(
        buildSupportedPaymentMethodData({
          amount: orderState.fiat_currency_amount,
          currency: orderState.fiat_currency,
          orderState,
        }),
        buildShoppingCartDetails({
          amount: orderState.fiat_currency_amount,
          currency: orderState.fiat_currency,
        })
      );

      request.canMakePayment().then((available) => {
        this.available = available;

        return available;
      }).finally(() => {
        onUpdate();

        if (this.available === null) {
          this.available = false;
        }
      }).catch(() => {
        this.available = false;
      });
    } catch (error: unknown) {
      this.available = false;
    }
  }

  // eslint-disable-next-line class-methods-use-this
  public isValidPaymentData (): boolean {
    return true;
  }


  public async onSubmit (data: GooglePayPaymentData, orderState: OrderState, formData: OrderDetailsFormData) {
    // eslint-disable-next-line @typescript-eslint/no-misused-promises, no-async-promise-executor
    return new Promise<void>(async (resolve) => {
      try {
        const request = new PaymentRequest(
          buildSupportedPaymentMethodData({
            amount: orderState.fiat_currency_amount,
            currency: orderState.fiat_currency,
            orderState,
          }),
          buildShoppingCartDetails({
            amount: orderState.fiat_currency_amount,
            currency: orderState.fiat_currency,
          })
        );

        const paymentResponse = await request.show();

        const response = await setPaymentToken({
          processing_order_id: orderState.processing_order_id,
          method: 'googlepay',
          token_raw_response: JSON.stringify(paymentResponse.details),
        });

        if (response.status === 'OK') {
          paymentResponse.complete('success');
        } else {
          paymentResponse.complete('fail');
        }

        resolve();
      } catch (error: unknown) {
        if (error instanceof Error) {
          logError(error, { orderId: orderState.processing_order_id, paymentType: this.getName() });
        }

        resolve();
      }
    });
  }
}

export default GooglePay;
