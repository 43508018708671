import { createAsyncThunk } from '@reduxjs/toolkit';
import { initPayment } from 'src/api';

import { TOrderInfoError } from './types';

export const fetchPaymentDataThunk = createAsyncThunk<
  string,
  { processingOrderId: string },
  {
    rejectValue: TOrderInfoError;
  }
>('fetchPaymentDataThunk', async (params, thunkApi) => {
  try {
    const response = await initPayment(params.processingOrderId);

    if (response.status === 'OK') {
      return response.pub_key;
    }

    if (response.error_code === 'ORDER_EXPIRED') {
      return thunkApi.rejectWithValue(response.error_code);
    }

    return thunkApi.rejectWithValue('UNKNOWN_ERROR');
  } catch {
    return thunkApi.rejectWithValue('UNKNOWN_ERROR');
  }
});
