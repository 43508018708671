import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { TitleProps } from 'src/models/PaymentMethod';

import styles from './GooglePayTitle.module.scss';

function GooglePayTitle({ className, orderState, ...props }: TitleProps) {
  const { t } = useTranslation();

  return (
    <div className={clsx(styles.container, className)} {...props}>
      <div className={styles.googlepayIcon} />

      Google Pay
    </div>
  );
}

export default GooglePayTitle;
