import { useCallback } from 'react';
import * as actions from 'src/redux/modules/snackbar/actions';
import { SnackbarType } from 'src/types/snackbar';

import { useAppDispatch, useAppSelector } from './redux';

function useSnackbar() {
  const dispatch = useAppDispatch();

  const snackbarValue = useAppSelector((state) => state.snackbar.snackbarValue);
  const snackbarType = useAppSelector((state) => state.snackbar.snackbarType);

  const setSnackbarValue = useCallback(
    (value: string, type = SnackbarType.Default) => {
      dispatch(
        actions.setSnackbarValue({ snackbarValue: value, snackbarType: type }),
      );
    },
    [],
  );

  return { snackbarValue, snackbarType, setSnackbarValue };
}

export default useSnackbar;
