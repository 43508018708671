import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SnackbarType } from 'src/types/snackbar';

import { TSnackbar } from './types';

const defaultState: TSnackbar = {
  snackbarValue: '',
  snackbarType: SnackbarType.Default,
};

const slice = createSlice({
  name: 'snackbar',
  initialState: defaultState,
  reducers: {
    setSnackbarValue: (
      _draftState,
      action: PayloadAction<{
        snackbarValue: string;
        snackbarType: SnackbarType;
      }>,
    ) => action.payload,
  },
});

export default slice;
