import { Typography } from '@acme/web-ui-kit';
import generalLegalTexts from '@acme/general-legal-texts';
import { TLanguages } from '@acme/ownr-i18n';
import { useI18n } from 'src/hooks/useI18n';

import Container from '../Container';
import Divider from '../Divider';

import styles from './Footer.module.scss';

function Footer () {
  const i18n = useI18n();

  const license = generalLegalTexts.ownr.license.general.info[i18n.language as TLanguages]?.body || generalLegalTexts.ownr.license.general.info.en.body;

  return (
    <footer className={styles.footer}>
      <Container className={styles.footerContainer}>
        <Divider className={styles.divider} />

        <div className={styles.footerContent}>
          <div className={styles.footerLogo} />

          <Typography variant="CaptionSmall" className={styles.footerLicense}>
            {license}
          </Typography>
        </div>
      </Container>
    </footer>
  );
}

export default Footer;
