import { useCallback } from 'react';
import * as actions from 'src/redux/modules/ordersInfo/actions';

import { useAppDispatch, useAppSelector } from './redux';

function useOrdersInfo() {
  const dispatch = useAppDispatch();

  const fetching = useAppSelector((state) => state.ordersInfo.fetching);
  const orderInfoError = useAppSelector(
    (state) => state.ordersInfo.orderInfoError,
  );
  const publicKey = useAppSelector((state) => state.ordersInfo.publicKey);

  const fetchPaymentData = useCallback((processingOrderId: string) => {
    dispatch(actions.fetchPaymentData({ processingOrderId }));
  }, []);

  const resetOrderInfo = useCallback(() => {
    dispatch(actions.resetOrderInfo());
  }, []);

  return {
    fetching,
    orderInfoError,
    publicKey,
    fetchPaymentData,
    resetOrderInfo,
  };
}

export default useOrdersInfo;
