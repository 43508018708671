import { useCallback, useEffect, useRef, useState } from 'react';

function useLoading (loadingValue: boolean, minExecutionTime: number) {
  const [isLoading, setIsLoading] = useState(loadingValue);
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);

  const exec = useCallback(() => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);

      timeoutRef.current = null;

      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    if (loadingValue) {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }

      setIsLoading(true);

      timeoutRef.current = setTimeout(exec, minExecutionTime);
    } else if (!timeoutRef.current) {
      setIsLoading(false)
    }
  }, [loadingValue]);

  useEffect(() => () => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
  }, []);

  return loadingValue || isLoading;
}

export default useLoading;
