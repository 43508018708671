import clsx from 'clsx';

import styles from './Divider.module.scss';
import { Props } from './Divider.types';

function Divider ({ className, ...props }: Props) {
  return <div className={clsx(styles.container, className)} {...props} />
}

export default Divider;
