import { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import useOrdersInfo from 'src/hooks/useOrdersInfo';
import useOrderState from 'src/hooks/useOrderState';
import { useInterval } from 'src/utils/hooks';
import { OrderMessage } from 'src/templates/types';
import { PAYMENT_STATE_FETCH_INTERVAL } from 'src/constants';
import FullPageLoader from 'src/components/FullPageLoader';
import { Button, v4 } from '@acme/web-ui-kit';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import useGlobalLoading from 'src/hooks/useGlobalLoading';
import { typedObjKeys } from 'src/utils/general';
import { logError } from 'src/services/logger';
import useLoading from 'src/hooks/useLoading';
import { isBirthdateValid } from 'src/utils/validators';
import config from 'src/config';
import { formatFormDate } from 'src/utils/helpers';

import * as AvailablePaymentMethods from './paymentMethods';
import OrderDetails from './OrderDetails';
import OrderAuthorize from './OrderAuthorize';
import { formatFormDefinition, getTemplate, isValidDetailsData } from './Order.utils';
import { OrderDetailsFormData } from './Order.types';
import { Props, PMethod } from './OrderAuthorize/OrderAuthorize.types';
import styles from './Order.module.scss';

const PAYMENT_METHOD_PRIORYTY = {
  bankCard: 3,
  applePay: 2,
  googlePay: 1,
  default: 0,
};

const Order = () => {
  const [searchParams] = useSearchParams();
  const { orderId } = useParams();
  const bankOrderId = searchParams.get('order_id');
  const { globalLoading, setGlobalLoading } = useGlobalLoading();
  const {
    publicKey,
    orderInfoError,
    fetching: fetchingOrderInfo,
    /* resetOrderInfo, */
    fetchPaymentData,
  } = useOrdersInfo();
  const { orderState, orderStateError, setOrderError/* , resetOrderState */, fetchOrderState } =
    useOrderState();
  const { t } = useTranslation();
  const [orderDetailsFormData, setOrderDetailsFormData] = useState<OrderDetailsFormData | null>(null);
  const [paymentData, setPaymentData] = useState<Props['paymentData'] | null>(null);
  const [availablePaymentMethods, setAvailablePaymentMethods] = useState<Array<PMethod> | null>(null);
  const [paymentMethodLastUpdate, setPaymentMethodLastUpdate] = useState<number>(Date.now());

  const paymentMethods = useMemo(() => {
    if (!availablePaymentMethods || availablePaymentMethods.some((item) => item.isAvailable() === null)) {
      return null;
    }

    return availablePaymentMethods
      .filter((item) =>
        item.isSupportPaymentMethod(orderState?.supported_payment_systems || []) && item.isAvailable())
      .sort((a, b) => PAYMENT_METHOD_PRIORYTY[b.getName()] - PAYMENT_METHOD_PRIORYTY[a.getName()]);
  },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [!!availablePaymentMethods, paymentMethodLastUpdate]);

  const paymentMethod = useMemo(() =>
    paymentMethods?.find((item) => paymentData?.name && item.getName() === paymentData.name),
    [paymentMethods, paymentData?.name]);

  const isSubmitAvailable = orderDetailsFormData?.acceptTerms?.value === 'true'
    /* @ts-expect-error payment data */
    && paymentMethod?.isValidPaymentData(paymentData, orderState, orderDetailsFormData)
    && isValidDetailsData(orderDetailsFormData)
    && (orderDetailsFormData.birthday ? isBirthdateValid(orderDetailsFormData.birthday.value) : true);

  const messageType = useMemo<OrderMessage | null>(() => {
    if (
      orderInfoError === 'UNKNOWN_ERROR' ||
      orderStateError === 'UNKNOWN_ERROR' ||
      !orderId
    ) {
      return 'UNKNOWN_ERROR';
    }

    if (
      orderInfoError === 'ORDER_EXPIRED' ||
      orderStateError === 'ORDER_EXPIRED' ||
      orderState?.status === 'expired'
    ) {
      return 'EXPIRED';
    }

    if (orderState?.status === 'error' && !orderState?.accepting_retry) {
      return 'FAILED';
    }

    if (orderState?.status === 'error' && orderState?.accepting_retry) {
      return 'ERROR';
    }

    if (orderState?.status === 'pending' || orderState?.status === 'wait3d') {
      return 'WAITING';
    }

    if (orderState?.status === 'completed') {
      return 'SUCCESS';
    }

    return null;
  }, [
    orderInfoError,
    orderStateError,
    orderId,
    orderState?.status,
    orderState?.accepting_retry,
  ]);

  /**
   * Интервал для запроса стейта формы
   * Если {null} - запросы не производятся
   */
  const intervalTimeout = useMemo(() => {
    if (!orderState
      || messageType === null
      || messageType === 'ERROR'
      || messageType === 'WAITING'
      || messageType === 'UNKNOWN_ERROR') {
      return PAYMENT_STATE_FETCH_INTERVAL;
    }

    return null;
  }, [messageType, orderState]);

  /**
   * Интервал для запроса платёжных данных
   * Если {null} - запросы не производятся
   */
  const intervalPaymentDataTimeout = useMemo(() => {
    if (!publicKey && !fetchingOrderInfo && orderInfoError) {
      return PAYMENT_STATE_FETCH_INTERVAL;
    }

    return null;
  }, [fetchingOrderInfo, orderInfoError, publicKey]);

  const orderContent = useMemo(() => {
    if (!orderState) {
      return null;
    }

    return (
      <>
        {orderDetailsFormData
          ? (
            <OrderDetails
              formData={orderDetailsFormData}
              onDataChange={setOrderDetailsFormData}
            />
          )
          : null}

        {orderState
          && publicKey
          && (messageType === 'ERROR' || messageType === null)
          && orderDetailsFormData
          && paymentMethods
          && paymentData
          ? (
            <OrderAuthorize
              orderState={orderState}
              formData={orderDetailsFormData}
              onFormDataChange={setOrderDetailsFormData}
              paymentMethods={paymentMethods}
              paymentData={paymentData}
              onChangePaymentMethod={setPaymentData}
            />
          )
          : null}
      </>
    );
  }, [messageType, orderDetailsFormData, orderState, paymentData, paymentMethods, publicKey]);

  const onContinue = useCallback(async () => {
    setGlobalLoading(true);

    if (paymentMethod) {
      try {
        // @ts-expect-error
        await paymentMethod.onSubmit(paymentData, orderState, orderDetailsFormData);

        if (orderState?.processing_order_id) {
          await fetchOrderState(orderState?.processing_order_id);
        }
      } catch (error: unknown) {
        if (orderState?.processing_order_id) {
          await fetchOrderState(orderState?.processing_order_id);
        }

        if (error instanceof Error) {
          logError(error, { orderId: orderState?.processing_order_id });
        }

        setOrderError('UNKNOWN_ERROR');
      }
    }

    setGlobalLoading(false);
  }, [fetchOrderState, orderDetailsFormData, orderState, paymentData, paymentMethod, setGlobalLoading, setOrderError]);

  const actions = useMemo(() => {
    const actionList: Array<{key: string, element: React.ReactNode}> = [];

    if (orderState?.url_on_failure) {
      actionList.push(
        {
          key: 'close',
          element: (
            <a
              className={styles.link}
              href={orderState.status === 'completed' && orderState.url_on_success
                ? orderState.url_on_success
                : orderState?.url_on_failure}
            >
              <Button variant="outlined" type="button" className={styles.button}>{t('close')}</Button>
            </a>
          ),
        },
      );
    }

    if (messageType === null || messageType === 'ERROR') {
      actionList.push(
        {
          key: 'submit',
          element: (
            <Button
              type="button"
              className={clsx(styles.button, styles.payButton)}
              disabled={!isSubmitAvailable}
              // eslint-disable-next-line @typescript-eslint/no-misused-promises
              onClick={onContinue}
            >
              <v4.Icon iconName="Shield" className={styles.payIcon} />
              {t('payform.submit')}
            </Button>),
        }
      );
    }

    return actionList;
  }, [orderState, messageType, t, isSubmitAvailable, onContinue]);

  const Template = useMemo(
    () => getTemplate({ formView: orderState?.form_view, fixedView: config.appConsumer }),
    [orderState?.form_view]
  );

  const fetchInitPayment = useCallback(() => {
    if (orderId) {
      fetchPaymentData(orderId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderId]);

  const fetchOrder = useCallback(() => {
    if (orderId) {
      fetchOrderState(orderId);
    }
  }, [orderId, fetchOrderState]);

  useEffect(() => {
    if (orderState?.form_definition && !orderDetailsFormData) {
      const formData = formatFormDefinition(orderState.form_definition);

      if (formData.birthday) {
        formData.birthday = {
          ...formData.birthday,
          value: formData.birthday.value && formatFormDate(formData.birthday.value)
        };
      }

      setOrderDetailsFormData({ ...formData, acceptTerms: { value: 'true', disabled: false, required: true } });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderState?.form_definition]);

  useEffect(() => {
    if (!bankOrderId) {
      fetchInitPayment();
      fetchOrder();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (
      (orderState?.status === 'new' ||
        (orderState?.status === 'error' && orderState?.accepting_retry)) &&
      !publicKey &&
      !fetchingOrderInfo
    ) {
      fetchInitPayment();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderState?.status, publicKey, fetchingOrderInfo]);

  useEffect(() => {
    if (paymentMethods && (paymentMethods?.length || 0) > 0 && !paymentData) {
      setPaymentData(paymentMethods[0].initialData)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paymentMethods?.length]);

  const onUpdate = useCallback(() => {
    setPaymentMethodLastUpdate(Date.now());
  }, []);

  useEffect(() => {
    if (orderState) {
      setAvailablePaymentMethods(typedObjKeys(AvailablePaymentMethods)
        .map((key) => new AvailablePaymentMethods[key](onUpdate, orderState)));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [!!orderState]);

  useInterval(() => {
    fetchOrder();
  }, intervalTimeout);

  useInterval(() => {
    fetchInitPayment();
  }, intervalPaymentDataTimeout);

  useEffect(() => {
    if (bankOrderId) {
      fetchOrder();
    }
  }, [bankOrderId, fetchOrder]);

  useEffect(() => {
    if (messageType !== null && messageType !== 'ERROR' && !orderDetailsFormData) {
      setOrderDetailsFormData((prevState) => prevState
        ? Object.fromEntries(typedObjKeys(prevState).map((key) =>
          [key, { ...prevState[key], disabled: true }]))
        : prevState);
    }

  }, [orderDetailsFormData, messageType]);

  useEffect(() => {
    if (orderState) {
      paymentMethod?.onStateUpdate(orderState);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paymentMethod, orderState?.status]);

  const loading = useLoading(
    globalLoading
      || !orderState
      || (!publicKey && (messageType === null || messageType === 'ERROR'))
      || messageType === 'WAITING'
      || !paymentMethods,
    1000);

  if (loading) {
    return <FullPageLoader />;
  }

  return (
    <Template
      orderState={orderState}
      messageType={messageType}
      orderContent={orderContent}
      actions={
        <div className={styles.actionList}>
          {actions.map((action) => (
            <Fragment key={action.key}>{action.element}</Fragment>
          ))}
        </div>
      }
    />
  );
};

export default Order;
