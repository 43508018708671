/* eslint-disable @typescript-eslint/no-non-null-assertion */
import clsx from 'clsx';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Typography } from '@acme/web-ui-kit';
import useSepaOrderState from 'src/hooks/useSepaOrderState';
import { getAdditionalFields } from 'src/utils/helpers';

import Timer from '../Timer';
import Requisite from '../Requisite';

import styles from './styles.module.scss';

const OrderDetails = () => {
  const { t } = useTranslation();
  const { sepaOrderState } = useSepaOrderState();

  const additionalFields = useMemo(() => {
    if (sepaOrderState?.form_definition) {
      return getAdditionalFields(
        sepaOrderState.form_definition,
        'label',
        'details',
      );
    }

    return {};
  }, [sepaOrderState]);

  if (sepaOrderState === null) {
    return null;
  }

  function renderFiatCoin() {
    if (!additionalFields.fiat_currency) {
      return null;
    }

    return (
      <div className={styles.coinContainer}>
        <div
          className={clsx(
            styles.fiatCoin,
            // eslint-disable-next-line css-modules/no-undef-class
            styles.small,
            styles[additionalFields.fiat_currency.defaultValue!],
          )}
        />
        <Typography variant="H4Normal">
          {additionalFields.fiat_currency.defaultValue}
        </Typography>
      </div>
    );
  }

  function renderCryptoCoin() {
    if (!additionalFields.cryptocurrency) {
      return null;
    }

    return (
      <div className={styles.coinContainer}>
        <div
          className={clsx(
            styles.cryptoCoin,
            // eslint-disable-next-line css-modules/no-undef-class
            styles.small,
            styles[additionalFields.cryptocurrency.defaultValue!],
          )}
        />
        <Typography variant="H4Normal">
          {additionalFields.cryptocurrency.defaultValue}
        </Typography>
      </div>
    );
  }

  return (
    <>
      <div className={styles.orderDetailsHeader}>
        <Typography variant="H1Normal">
          {t('orderStatus.orderDetails')}
        </Typography>
        <Timer expireAt={sepaOrderState.expires_at} />
      </div>

      {Object.keys(additionalFields).length > 0 && (
        <>
          <div className={styles.orderDetailsAmounts}>
            {additionalFields.fiat_currency_amount?.defaultValue ? (
              <Requisite
                label={`${t('sepa.payment_amount')} \u00B9`}
                placeholder="0.00"
                value={additionalFields.fiat_currency_amount.defaultValue}
                className={styles.amount}
                rightComponent={renderFiatCoin()}
                disabled
              />
            ) : null}
            {additionalFields.cryptocurrency_amount?.defaultValue ? (
              <Requisite
                label={`${t('sepa.receiving_amount')} \u00B2`}
                placeholder="0.00"
                value={additionalFields.cryptocurrency_amount.defaultValue}
                className={styles.amount}
                rightComponent={renderCryptoCoin()}
                disabled
              />
            ) : null}
          </div>
          {additionalFields.cryptocurrency_payout_address?.defaultValue ? (
            <Requisite
              label={t('payform.formDefinitions.receivingAddress')}
              placeholder="0.00"
              value={
                additionalFields.cryptocurrency_payout_address.defaultValue
              }
              className={clsx(styles.amount, styles.address)}
              disabled
            />
          ) : null}
        </>
      )}
      <div className={styles.orderDetailsAdditionalInfoContainer}>
        <Typography variant="BodyNormal">
          1. {t('sepa.order_details_additional_info_fee')}
        </Typography>
        <Typography variant="BodyNormal">
          2. {t('sepa.order_details_additional_info')}
        </Typography>
      </div>
    </>
  );
};

export default OrderDetails;
