import { useTranslation } from 'react-i18next';
import { Typography } from '@acme/web-ui-kit';
import useSepaOrderState from 'src/hooks/useSepaOrderState';

import styles from './styles.module.scss';

const UserRequisites = () => {
  const { t } = useTranslation();
  const { sepaOrderState } = useSepaOrderState();

  if (!sepaOrderState) {
    return null;
  }

  const { user_requisite } = sepaOrderState;
  const { first_name, last_name, iban } = user_requisite;

  return (
    <div className={styles.userRequisites}>
      <Typography variant="BodyNormal" className={styles.userRequisiteLabel}>
        {`${t('sepa.requisite.customer_name')}:`}
      </Typography>
      <Typography variant="H4Normal">{`${first_name} ${last_name}`}</Typography>

      {/* {bank_address ? (
        <>
          <Typography
            variant="BodyNormal"
            className={styles.userRequisiteLabel}
          >
            {`${t('sepa.requisite.address')}:`}
          </Typography>
          <Typography variant="BodyNormal">{bank_address}</Typography>
        </>
      ) : null} */}

      {iban ? (
        <>
          <Typography
            variant="BodyNormal"
            className={styles.userRequisiteLabel}
          >
            {`${t('sepa.requisite.iban')}:`}
          </Typography>
          <Typography variant="BodyNormal">{iban}</Typography>
        </>
      ) : null}

      {/* {bank_swift_code ? (
        <>
          <Typography
            variant="BodyNormal"
            className={styles.userRequisiteLabel}
          >
            {`${t('sepa.requisite.bank_swift_code')}:`}
          </Typography>
          <Typography variant="BodyNormal">{bank_swift_code}</Typography>
        </>
      ) : null} */}
    </div>
  );
};

export default UserRequisites;
