import { useEffect, useMemo, useRef, useState } from 'react';
import config from 'src/config';

/**
 * Get time between expireAt and now
 * @param expireAt date and time in ISO format
 */
const getTimeLeftParts = (
  expireAt: string,
): [number, number, number] | [number, number] => {
  if (new Date(expireAt) < new Date()) {
    return [0, 0];
  }

  const deltaDate = new Date(new Date(+new Date(expireAt) - Date.now()));

  const hours = deltaDate.getUTCHours();
  const minutes = deltaDate.getUTCMinutes();
  const seconds = deltaDate.getUTCSeconds();

  if (hours > 0) {
    return [hours, minutes, seconds];
  }

  return [minutes, seconds];
};

/**
 * Timer between expireAt and now
 * @param expireAt date and time in ISO format
 * @returns 'nn:nn:nn' | 'nn:nn'
 */
const useTimer = (expireAt: string | null) => {
  const [count, setCount] = useState<number>(0);
  const intervalId = useRef<NodeJS.Timer | null>();

  const timeLeft = useMemo(() => {
    if (expireAt) {
      const timeLeftParts = getTimeLeftParts(expireAt);

      const formattedTime = timeLeftParts
        .map((item) => item.toString().padStart(2, '0'))
        .join(':');

      if (formattedTime === '00:00' && intervalId.current) {
        clearInterval(intervalId.current);
      }

      return formattedTime;
    }

    return null;
  }, [expireAt, count]);

  useEffect(() => {
    if (config.environment === 'development') {
      if (typeof expireAt === 'string' && Number.isNaN(Date.parse(expireAt))) {
        throw new TypeError(
          `useTimer: invalid parameter expireAt (${expireAt})`,
        );
      }
    }

    if (expireAt) {
      intervalId.current = setInterval(() => {
        setCount((state) => state + 1);
      }, 1000);
    }

    return () => {
      if (intervalId.current) {
        clearInterval(intervalId.current);
      }
    };
  }, [expireAt]);

  /**
   * Stop interval when timer ends
   */
  useEffect(() => {
    if (timeLeft === '00:00') {
      if (intervalId.current) {
        clearInterval(intervalId.current);
      }
    }
  }, [timeLeft]);

  return timeLeft;
};

export default useTimer;
