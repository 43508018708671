
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { Typography } from '@acme/web-ui-kit';
import Divider from 'src/components/Divider';

import Requisite from './Requisite';
import styles from './OrderDetails.module.scss';
import { Props } from './OrderDetails.types';

function OrderDetails({ formData, onDataChange }: Props) {
  const { t } = useTranslation();

  if (Object.keys(formData).length === 0) {
    return null;
  }

  function renderFiatCoin () {
    if (!formData.fiat_currency) {
      return null;
    }

    return (
      <div className={styles.coinContainer}>
        <div className={styles.currencyIconContainer}>
          <div
            className={clsx(
              styles.fiatCoin,
              // eslint-disable-next-line css-modules/no-undef-class
              styles.small,
              styles[formData.fiat_currency.value],
            )}
          />
        </div>

        <Typography variant="H4Normal" className={styles.currencyText}>
          {formData.fiat_currency.value}
        </Typography>
      </div>
    );
  }

  function renderCryptoCoin () {
    if (!formData.cryptocurrency) {
      return null;
    }

    return (
      <div className={styles.coinContainer}>
        <div className={styles.currencyIconContainer}>
          <div
            className={clsx(
              styles.cryptoCoin,
              // eslint-disable-next-line css-modules/no-undef-class
              styles.small,
              // eslint-disable-next-line css-modules/no-undef-class
              styles.DEFAULT,
              styles[formData.cryptocurrency.value],
            )}
          />
        </div>
        <Typography variant="H4Normal" className={styles.currencyText}>
          {formData.cryptocurrency.value}
        </Typography>
      </div>
    );
  }

  if (formData.fiat_currency_amount?.value === undefined
    && formData.cryptocurrency_amount?.value === undefined
    && formData.cryptocurrency_payout_address?.value === undefined) {
    return null;
  }

  return (
    <div className={styles.container}>
      <div className={styles.orderDetailsAmounts}>
        {formData.fiat_currency_amount?.value !== undefined ? <Requisite
          label={t('sepa.payment_amount')}
          placeholder="0.00"
          value={formData.fiat_currency_amount?.value}
          className={clsx(styles.amount, styles.fiatAmount)}
          rightComponent={renderFiatCoin()}
          disabled
        /> : null}

        {formData.cryptocurrency_amount?.value !== undefined ? <Requisite
          label={t('sepa.receiving_amount')}
          placeholder="0.00"
          value={formData.cryptocurrency_amount?.value}
          rightComponent={renderCryptoCoin()}
          className={styles.amount}
          disabled
        /> : null}
      </div>

      {formData.cryptocurrency_payout_address?.value !== undefined ? (
        <Requisite
          label={t('payform.formDefinitions.receivingAddress')}
          autoComplete="none"
          disabled={formData.cryptocurrency_payout_address?.disabled}
          value={formData.cryptocurrency_payout_address?.value}
          className={styles.payoutAddress}
          onChange={(event) => {
            const { value } = event.target;

            onDataChange({
              ...formData,
              cryptocurrency_payout_address:
                formData.cryptocurrency_payout_address
                  ? { ...formData.cryptocurrency_payout_address, value }
                  : formData.cryptocurrency_payout_address,
            });
          }}
        />
      ) : null}

      <Divider className={styles.divider} />
    </div>
  );
};

export default OrderDetails;
