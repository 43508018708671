import { MouseEventHandler } from 'react';
import clsx from 'clsx';
import { Button, v3 } from '@acme/web-ui-kit';

import styles from './Requisite.module.scss';

const Requisite = ({ value, className, ...props }: v3.TInputProps) => {
  const onClick: MouseEventHandler<HTMLButtonElement> = () => {
    if (value) {
      navigator.clipboard.writeText(value.toString());
    }
  };

  return (
    <v3.Input
      value={value}
      isPersistentPlaceholder
      rightComponent={
        <Button variant="text" onClick={onClick} className={styles.button} />
      }
      className={clsx(styles.input, className)}
      {...props}
    />
  );
};

export default Requisite;
