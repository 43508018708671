import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import { i18nextResources } from './constants';
import { changeLanguage } from './utils';

i18n.on('languageChanged', (language: string) => {
  changeLanguage(language);
});

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    fallbackLng: 'en-US',
    resources: i18nextResources,
    detection: {
      htmlTag: document.documentElement,
    },
  });
