import { ComponentProps } from 'src/models/PaymentMethod';

import { ApplePayPaymentData } from './ApplePay.types';

// eslint-disable-next-line no-empty-pattern
function ApplePay ({}: ComponentProps<ApplePayPaymentData>) {
  return null;
}

export default ApplePay;
