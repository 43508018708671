import {
  DefinitionSection,
  DefinitionType,
  FormDefinition,
} from 'src/types/order';

export function getAdditionalFields(
  fieldsJson: string,
  fieldType: DefinitionType,
  fieldSection: DefinitionSection,
  exclude: ReadonlyArray<string> = [],
  include: ReadonlyArray<string> = [],
): Partial<Record<string, FormDefinition>> {
  const fieldsParsed: Record<string, FormDefinition> = JSON.parse(fieldsJson);

  const fields = Object.keys(fieldsParsed).filter(
    (l) =>
      fieldsParsed[l].fieldType === fieldType &&
      fieldsParsed[l].section === fieldSection &&
      !exclude.includes(l) &&
      (include.length === 0 || include.includes(l)),
  );

  return Object.fromEntries(
    fields.map<[string, FormDefinition]>((key) => [key, fieldsParsed[key]]),
  );
}

export function fieldToTitle(field: string) {
  const [first, ...rest] = field.split('_');
  return `${first.charAt(0).toUpperCase()}${first.slice(1)} ${rest.join(
    ' ',
  )}`.trim();
}

export function formatFormDate (date: string) {
  try {
    return new Date(date).toISOString().slice(0, 10);
  } catch (error: unknown) {
    return ''
  }
}
