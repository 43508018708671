import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { OrderState } from 'src/types/order';

import type { TOrderStateError, TOrderStateSlice } from './types';
import { fetchOrderStateThunk } from './thunks';

const defaultState: TOrderStateSlice = {
  orderState: null,
  orderStateError: null,
};

const slice = createSlice({
  name: 'orderState',
  initialState: defaultState,
  reducers: {
    setOrderState: (
      draftState,
      action: PayloadAction<{ orderState: OrderState }>,
    ) => {
      draftState.orderState = action.payload.orderState;
    },
    setOrderError: (draftState, action: PayloadAction<TOrderStateError | null>) => {
      draftState.orderStateError = action.payload;
    },
    resetOrderState: () => defaultState,
  },
  extraReducers: (builder) => {
    builder.addCase(
      fetchOrderStateThunk.fulfilled,
      (draftState, { payload }) => {
        draftState.orderStateError = null;
        draftState.orderState = payload;
      },
    );

    builder.addCase(
      fetchOrderStateThunk.rejected,
      (draftState, { payload }) => {
        draftState.orderStateError = null;

        if (payload) {
          draftState.orderStateError = payload;
        }
      },
    );
  },
});

export default slice;
