import { createAsyncThunk } from '@reduxjs/toolkit';
import { getSepaOrderState, startSepaOrder } from 'src/api';
import { TSepaOrderState } from 'src/types/sepaOrder';

import { TSepaOrderStateError } from './types';

export const fetchSepaOrderStateThunk = createAsyncThunk<
  TSepaOrderState,
  { orderId: string },
  {
    rejectValue: TSepaOrderStateError;
  }
>('fetchSepaOrderStateThunk', async ({ orderId }, thunkApi) => {
  try {
    const response = await getSepaOrderState(orderId);

    if (response.status === 'OK') {
      return response.order;
    }

    if (response.error_code === 'ORDER_EXPIRED') {
      return thunkApi.rejectWithValue(response.error_code);
    }

    return thunkApi.rejectWithValue('UNKNOWN_ERROR');
  } catch {
    return thunkApi.rejectWithValue('UNKNOWN_ERROR');
  }
});

export const fetchStartSepaOrderThunk = createAsyncThunk<
  TSepaOrderState,
  { orderId: string },
  {
    rejectValue: TSepaOrderStateError;
  }
>('fetchStartSepaOrderThunk', async ({ orderId }, thunkApi) => {
  try {
    const response = await startSepaOrder(orderId);

    if (response.status === 'OK') {
      return response.order;
    }

    if (response.error_code === 'ORDER_EXPIRED') {
      return thunkApi.rejectWithValue(response.error_code);
    }

    return thunkApi.rejectWithValue('UNKNOWN_ERROR');
  } catch {
    return thunkApi.rejectWithValue('UNKNOWN_ERROR');
  }
});
