import apiClient from '../client';

export default async function startSepaOrder(
  orderId: string,
  customerIp?: string | null,
) {
  return apiClient.processingSepaStartOrder({
    order_id: orderId,
    customer_ip: customerIp,
  });
}
