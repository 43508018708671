import { useEffect, useRef } from 'react';

export const useInterval = (callback: () => void, delay: number | null) => {
  const savedCallback = useRef(callback);

  useEffect(() => {
    savedCallback.current = callback;
  });

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (delay) {
      const interval = setInterval(() => savedCallback.current(), delay);

      return () => {
        clearInterval(interval);
      };
    }
  }, [delay]);
};
