import clsx from 'clsx';

import { Props } from './Container.types';
import styles from './Container.module.scss';

function Container ({ size = 'md', className, ...props }: Props) {
  return <div className={clsx(styles.container, className)} {...props} />
}

export default Container;
