const YEAR = 31556926000;

export function isBirthdateValid (birthdate: string): boolean {
  if (!birthdate || Number.isNaN(Date.parse(birthdate))) return false;

  const diffDate = Date.now() - Date.parse(birthdate);

  if (diffDate < YEAR * 18 || diffDate > YEAR * 100) {
    return false;
  }

  return true;
}
