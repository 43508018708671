import PaymentMethodError from 'src/models/PaymentMethodError';
import PaymentMethod from 'src/models/PaymentMethod';
import { ProcessingPaymentFormState } from '@acme/external-processing-api-client/dist/types';
import { OrderState } from 'src/types/order';
import config from 'src/config';
import { setPaymentToken, validateMerchant } from 'src/api';
import { logError } from 'src/services/logger';

import { OrderDetailsFormData } from '../../Order.types';

import ApplePayComponent from './ApplePay.component';
import ApplePayTitleComponent from './ApplePayTitle.component';
import { ApplePayPaymentData } from './ApplePay.types';

class ApplePay extends PaymentMethod<'applePay', ApplePayPaymentData> {
  initialData: ApplePayPaymentData = {
    name: 'applePay',
  };

  paymentTypes = ['applepay'];

  Component = ApplePayComponent;

  TitleComponent = ApplePayTitleComponent;

  available: boolean | null = typeof ApplePaySession !== 'undefined' && !!ApplePaySession;

  // eslint-disable-next-line class-methods-use-this
  public isValidPaymentData (): boolean {
    return true;
  }


  public async onSubmit (data: ApplePayPaymentData, orderState: OrderState, formData: OrderDetailsFormData) {
    return new Promise<void>((resolve) => {
      try {
        const request = {
          countryCode: 'EE',
          currencyCode: orderState.fiat_currency,
          merchantCapabilities: ['supports3DS'],
          supportedNetworks: ([
              'visa',
              'masterCard',
            ] as const).filter((item) => orderState.supported_payment_systems.includes(item?.toLocaleLowerCase() as Lowercase<typeof item>)),
          total: {
            label: 'OWNR',
            amount: orderState.fiat_currency_amount,
            type: 'final',
          },
        };

        const session = new ApplePaySession(3, request);

        session.onvalidatemerchant = async (event: unknown) => {
          const body = event as { validationURL?: string };

          // Call your own server to request a new merchant session.
          const merchantSession = await validateMerchant({
            processing_order_id: orderState?.processing_order_id || '',
            url: body.validationURL || '',
          });

          if (merchantSession.status === 'OK') {
            const merchantSessionData = JSON.parse(merchantSession.merchant_session);

            session.completeMerchantValidation(merchantSessionData);
          }
        };

        session.onpaymentmethodselected = () => {
          // Define ApplePayPaymentMethodUpdate based on the selected payment method.
          // No updates or errors are needed, pass an empty object.
          const update = {
            newTotal: request.total,
          };
          session.completePaymentMethodSelection(update);
        };

        session.onshippingmethodselected = () => {
          const update = {};
          session.completeShippingMethodSelection(update);
        };

        session.onshippingcontactselected = () => {
          const update = {};
          session.completeShippingContactSelection(update);
        };

        session.onpaymentauthorized = async (event) => {
          const { payment } = event as { payment: { token: unknown } };
          const response = await setPaymentToken({
            processing_order_id: orderState?.processing_order_id || '',
            method: 'applepay',
            token_raw_response: JSON.stringify(payment.token),
          });

          if (response.http_status_code !== 200) {
            throw new PaymentMethodError(response.error_code);
          }

          const result = {
            status:
              response.http_status_code === 200
                ? ApplePaySession.STATUS_SUCCESS
                : ApplePaySession.STATUS_FAILURE,
          };

          session.completePayment(result);

          resolve();
        };

        // eslint-disable-next-line unicorn/prefer-add-event-listener
        session.oncancel = () => {
          resolve();
        };

        session.begin();
      } catch (error: unknown) {
        if (error instanceof Error) {
          logError(error, { orderId: orderState.processing_order_id, paymentType: this.getName() });
        }

        resolve();
      }
    });
  }
}

export default ApplePay;
