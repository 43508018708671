import PaymentMethodError from 'src/models/PaymentMethodError';
import PaymentMethod from 'src/models/PaymentMethod';
import { getCardSystem, isValidCVV, isValidExpiryDate, isValidPAN } from 'src/utils/card';
import { OrderState } from 'src/types/order';
import { encode } from 'src/lib/encode';
import authorize, { build3dSecureData } from 'src/api/methods/authorize';
import { initPayment } from 'src/api';
import { typedObjKeys } from 'src/utils/general';
import { ProcessingPaymentFormState } from '@acme/external-processing-api-client/dist/types';

import { OrderDetailsFormData } from '../../Order.types';

import BankCardComponent from './BankCard.component';
import { BankCardPaymentData } from './BankCard.types';
import BankCardTitleComponent from './BankCardTitle.component';

class BankCard extends PaymentMethod<'bankCard', BankCardPaymentData> {
  initialData: BankCardPaymentData = {
    name: 'bankCard',
    pan: '',
    expiryDate: '',
    cvv: '',
    holderName: '',
  };

  paymentTypes = ['mastercard', 'visa'];

  available = true;

  Component = BankCardComponent;

  TitleComponent = BankCardTitleComponent;

  static executeForm3D (form3d: string) {
    const [form3dCleaned] = form3d.split('<script>');

    const htmlObject = document.createElement('div');

    htmlObject.innerHTML = form3dCleaned;

    document.body.appendChild(htmlObject);
    document.forms.namedItem('form3d')?.submit();
  }

  public onStateUpdate (orderState: ProcessingPaymentFormState): void {
    super.onStateUpdate(orderState);

    if (orderState.status === 'wait3d' && orderState.form3d) {
      BankCard.executeForm3D(orderState.form3d);
    }
  }

// eslint-disable-next-line class-methods-use-this
  public isValidPaymentData(data: BankCardPaymentData, orderState: OrderState, formData: OrderDetailsFormData) {
    const cardType = getCardSystem(data.pan);

    return isValidPAN(data.pan)
      && !!cardType
      && (orderState.supported_payment_systems as Array<string>).includes(cardType?.toLocaleLowerCase())
      && isValidExpiryDate(data.expiryDate)
      && isValidCVV(data.cvv)
      && data.holderName.length > 2;
  }

  // eslint-disable-next-line class-methods-use-this
  public async onSubmit (data: BankCardPaymentData, orderState: OrderState, formData: OrderDetailsFormData) {
    // eslint-disable-next-line @typescript-eslint/no-misused-promises, no-async-promise-executor
    return new Promise<void>(async (resolve, reject) => {
      try {
        const [expirationMonth = '', expirationYear = ''] = data.expiryDate.split('/');

        const additionalFields: Record<string, boolean | number | string> = Object.fromEntries(typedObjKeys(formData).map((key) => [key, formData[key]?.value || '']));

        const cardData = {
          pan: data.pan.split(' ').join(''),
          cvv: data.cvv,
          holder: data.holderName.toUpperCase(),
          expiration_month: Number(expirationMonth.trim()),
          expiration_year: Number(`20${expirationYear.trim()}`),
        };

        const initData = await initPayment(orderState.processing_order_id);

        if (initData.status !== 'OK') {
          reject(new PaymentMethodError('UNKNOWN_ERROR'));
          return;
        }

        const encodedCardData = await encode(cardData, initData.pub_key);

        const encodedFormValues = await encode(additionalFields, initData.pub_key);

        const secure3dData = build3dSecureData();
        const encodedSecure3dData = await encode(secure3dData, initData.pub_key);

        const newOrderState = await authorize({
          processing_order_id: orderState.processing_order_id,
          customer_card_data: encodedCardData,
          form_values: encodedFormValues,
          secure3d_data: encodedSecure3dData,
        });

        if (newOrderState.form3d) {
          BankCard.executeForm3D(newOrderState.form3d)
        } else {
          resolve();
        }
      } catch (error: unknown) {
        reject(new PaymentMethodError('UNKNOWN_ERROR'));
      }
    });
  }
}

export default BankCard;
