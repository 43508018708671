import { useCallback } from 'react';
import * as actions from 'src/redux/modules/sepaOrderState/actions';

import { useAppDispatch, useAppSelector } from './redux';

function useSepaOrderState() {
  const dispatch = useAppDispatch();

  const sepaOrderState = useAppSelector(
    (state) => state.sepaOrderState.sepaOrderState,
  );

  const sepaOrderStateError = useAppSelector(
    (state) => state.orderState.orderStateError,
  );

  const fetchSepaOrderState = useCallback((orderId: string) => {
    dispatch(actions.fetchSepaOrderState({ orderId }));
  }, []);

  const fetchStartSepaOrder = useCallback(
    (orderId: string) => dispatch(actions.fetchStartSepaOrder({ orderId })),
    [],
  );

  return {
    sepaOrderState,
    sepaOrderStateError,
    fetchSepaOrderState,
    fetchStartSepaOrder,
  };
}

export default useSepaOrderState;
