import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { v3 } from '@acme/web-ui-kit';
import { parseDefaultLanguage } from 'src/i18n/parseDefaultLanguage';
import Language from 'src/assets/language.svg';
import { languageList } from 'src/i18n/constants';

import styles from './styles.module.scss';
import RenderItem, { TItem } from './RenderItem';

const ChangeLanguage = () => {
  const { i18n } = useTranslation();

  const [lang, setLang] = useState(parseDefaultLanguage(i18n.language));
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    i18n.changeLanguage(lang);
  }, [lang, i18n]);

  const onChangeLanguage = useCallback((value: string) => {
    setLang(value);
    setVisible(false);
  }, []);

  const renderItem = useCallback(
    (item: TItem) => <RenderItem onChange={onChangeLanguage} item={item} />,
    [onChangeLanguage],
  );

  const renderLabel = (item: TItem) => (
    <div className={styles.label}>
      <img src={Language} className={styles.languageIcon} alt="Language" />
      {item.label}
    </div>
  );

  const onClose = () => {
    setVisible(false);
  };

  const onClick = () => {
    setVisible(!visible);
  };

  const keyExtractor = (item: TItem) => item.value;

  const data: Array<TItem> = useMemo(() => languageList.map((item) => ({
    value: item[0],
    label: item[1],
  })), []);

  return (
    <v3.BasicSelect
      classNameWrapper={styles.select}
      variant="text"
      visible={visible}
      value={lang}
      data={data}
      renderLabel={renderLabel}
      renderItem={renderItem}
      keyExtractor={keyExtractor}
      onClose={onClose}
      onClick={onClick}
    />
  );
};

export default ChangeLanguage;
