import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { TGlobalLoading } from './types';

const defaultState: TGlobalLoading = {
  loading: false,
};

const slice = createSlice({
  name: 'globalLoading',
  initialState: defaultState,
  reducers: {
    setLoading: (draftState, action: PayloadAction<boolean>) => {
      draftState.loading = action.payload;
    },
  },
});

export default slice;
