import { Typography, Button } from '@acme/web-ui-kit';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import Divider from 'src/components/Divider';

import styles from './FailedMessage.module.scss';

function FailedMessage() {
  const { t } = useTranslation();

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <div className={styles.icon} />

        <Typography variant="H4Normal" className={styles.description}>
          {t('payform.payment_failed.title')}
        </Typography>
      </div>

      <Divider className={styles.divider} />
    </div>
  );
}

export default FailedMessage;
