import { useCallback } from 'react';
import * as actions from 'src/redux/modules/orderState/actions';
import { TOrderStateError } from 'src/redux/modules/orderState/types';
import { OrderState } from 'src/types/order';

import { useAppDispatch, useAppSelector } from './redux';

function useOrderState() {
  const dispatch = useAppDispatch();

  const orderState = useAppSelector((state) => state.orderState.orderState);
  const orderStateError = useAppSelector(
    (state) => state.orderState.orderStateError,
  );

  const setOrderState = useCallback(
    (paymentFormState: OrderState) => {
      dispatch(actions.setOrderState({ orderState: paymentFormState }));
    },
    [],
  );

  const resetOrderState = useCallback(() => {
    dispatch(actions.resetOrderState());
  }, []);

  const fetchOrderState = useCallback((paymentFormUniqueId: string) => dispatch(actions.fetchOrderState({ paymentFormUniqueId })), []);

  const setOrderError = useCallback((error: TOrderStateError | null) => dispatch(actions.setOrderError(error)), []);

  return {
    orderState,
    orderStateError,
    setOrderState,
    setOrderError,
    resetOrderState,
    fetchOrderState,
  };
}

export default useOrderState;
