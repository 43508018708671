import creditCardType from 'credit-card-type';

import { typedObjKeys } from './general';

const cardSystemsRegexp = {
  masterCard:
    /^5[1-5]\d{14}$|^2(?:2(?:2[1-9]|[3-9]\d)|[3-6]\d\d|7(?:[01]\d|20))\d{12}$/,
  americanExpress: /^3[47]\d{13}$/,
  visa: /^4\d{12}(?:\d{3})?$/,
  discover:
    /^65[4-9]\d{13}|64[4-9]\d{13}|6011\d{12}|(622(?:12[6-9]|1[3-9]\d|[2-8]\d\d|9[01]\d|92[0-5])\d{10})$/,
  maestro:
    /^(5018|5081|5044|5020|5038|603845|6304|6759|676[1-3]|6799|6220|504834|504817|504645)\d{8,15}$/,
  jcb: /^(?:2131|1800|35\d{3})\d{11}$/,
  dinersClub: /^3(?:0[0-5]|[68]\d)\d{11}$/,
  unionpay: /^(62\d{14,17})$/,
  mir: /^(220[0-4]\d{12})$/
};

export const isValidPAN = (pan: string) => {
  const value = pan.replace(/\D/g, '');
  const digits = [...value].reverse();
  let shouldDouble = false;

  if (value.length < 16) {
    return false;
  }

  const sum = digits.reduce<number>((accum, item) => {
    let digit = parseInt(item, 10);
    if (shouldDouble) {
      digit *= 2;
      if (digit > 9) {
        digit -= 9;
      }
    }
    shouldDouble = !shouldDouble;
    return accum + digit;
  }, 0);

  return sum % 10 === 0;
};

/**
 * TODO: replace by self made method
 */
export const getCardType = (pan: string) => creditCardType(pan);

export function getCardSystem(
  cardNumber: string,
): keyof typeof cardSystemsRegexp | null {
  const clearedCardNumber = cardNumber.replace(/\s/g, '');

  return (
    typedObjKeys(cardSystemsRegexp).find((cardSystem) =>
      clearedCardNumber.match(cardSystemsRegexp[cardSystem]),
    ) || null
  );
}

export function isValidExpiryDate (expirationDate: string) {
  if (expirationDate) {
    const [month, expirationYear] = expirationDate.split('/');
    const expirationMonth = Number(month);
    if (expirationMonth < 1 || expirationMonth > 12) {
      return false;
    }

    const currentYear = new Date().getFullYear();
    const fullExpirationYear = Number(`20${expirationYear?.trim()}`);

    if (currentYear > fullExpirationYear) {
      return false
    }
    if (
      currentYear === fullExpirationYear &&
      expirationMonth <= new Date().getMonth() + 1
    ) {
      return false
    }

    return true;
  }

  return false;
}

export function isValidCVV (cvv: string) {
  return cvv.length === 3 && /^\d{3}$/.test(cvv);
}
