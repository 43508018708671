import { useTranslation } from 'react-i18next';
import useSepaOrderState from 'src/hooks/useSepaOrderState';

import Requisite from '../Requisite';

const OwnrRequisites = () => {
  const { t } = useTranslation();
  const { sepaOrderState } = useSepaOrderState();

  if (!sepaOrderState) {
    return null;
  }

  const { ownr_requisite } = sepaOrderState;
  const { beneficiary_name, iban, addressable_in } = ownr_requisite;

  return (
    <>
      <Requisite
        label={t('sepa.requisite.beneficiary_name')}
        value={beneficiary_name}
      />
      <Requisite label={t('sepa.requisite.iban')} value={iban} />
      <Requisite
        label={t('sepa.requisite.addressable_in')}
        value={addressable_in}
      />
    </>
  );
};

export default OwnrRequisites;
