import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { TitleProps } from 'src/models/PaymentMethod';

import styles from './ApplePayTitle.module.scss';

function ApplePayTitle({ className, orderState, ...props }: TitleProps) {
  const { t } = useTranslation();

  return (
    <div className={clsx(styles.container, className)} {...props}>
      <div className={styles.applepayIcon} />

      Apple Pay
    </div>
  );
}

export default ApplePayTitle;
