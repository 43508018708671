import { HTMLAttributes, ReactNode } from 'react';
import { OrderDetailsFormData } from 'src/pages/Order/Order.types';
import { OrderState } from 'src/types/order';

type OwnRenderProps<PaymentData> = {
  data: PaymentData;
  orderState: OrderState;
  errors: Partial<Record<keyof PaymentData, string>>;
  onDataChange: (data: PaymentData) => void;
};

export type ComponentProps<PaymentData> = Omit<
  HTMLAttributes<HTMLDivElement>,
  keyof OwnRenderProps<PaymentData>
> &
  OwnRenderProps<PaymentData>;

export type TitleProps = HTMLAttributes<HTMLDivElement> & {
  orderState: OrderState;
};

abstract class PaymentMethod<
  PaymentName extends string,
  PaymentData extends { name: PaymentName },
> {

  protected available: boolean | null = null;

  public constructor (onUpdate: () => void, orderState: OrderState) {
    setTimeout(() => {
      if (this.available === null) {
        this.available = false;
      }

      onUpdate();
    }, 5000);
  }

  protected abstract readonly initialData: PaymentData;

  protected abstract readonly paymentTypes: Array<string>;

  public abstract isValidPaymentData(data: PaymentData, orderState: OrderState, formData: OrderDetailsFormData): boolean;

  public abstract onSubmit(data: PaymentData, orderState: OrderState, formData: OrderDetailsFormData): Promise<void>;

  public abstract Component: React.FC<ComponentProps<PaymentData>>;

  public abstract TitleComponent: React.FC<TitleProps>;

  public getName () {
    return this.initialData.name;
  }

  public isAvailable() {
    return this.available;
  }

  public getInitialData() {
    return this.initialData;
  }

  // eslint-disable-next-line class-methods-use-this, @typescript-eslint/no-empty-function
  public onStateUpdate (orderState: OrderState) {}

  public isSupportPaymentMethod(paymentTypes: Array<string>) {
    return paymentTypes.some((paymentMethod) =>
      this.paymentTypes.includes(paymentMethod),
    );
  }
}

export default PaymentMethod;
