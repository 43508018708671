import { useMemo } from 'react';
import { Link, Checkbox, v3, v4 } from '@acme/web-ui-kit';
import { useTranslation } from 'react-i18next';
import PaymentMethod from 'src/components/PaymentMethod';
import useOrderState from 'src/hooks/useOrderState';
import { isBirthdateValid } from 'src/utils/validators';

import { formatField, formDefinitionI18nMap } from './OrderAuthorize.utils';
import { Props } from './OrderAuthorize.types';
import styles from './OrderAuthorize.module.scss';

const OrderAuthorize = ({
  orderState,
  formData,
  onFormDataChange,
  paymentMethods,
  paymentData,
  onChangePaymentMethod,
}: Props) => {
  const { t } = useTranslation();
  const { orderStateError } = useOrderState();

  const errorMessage = useMemo(() => {
    switch (orderState?.error_code) {
      case 'GEO_ISSUE':
      case 'FORM_VALIDATION_ERROR':
      case 'CARD_VALIDATION_ERROR':
      case 'DECLINED':
      case 'REJECTED':
      case 'LIMITS_EXCEEDED': {
        return t('payform.payment_failed.title');
      }
      default: {
        return t('errors.UNKNOWN_ERROR');
      }
    }
  }, [orderState?.error_code, t]);

  return (
    <div className={styles.container}>
      <div className={styles.paymentMethods}>
        {paymentMethods.map((paymentMethod) => (
          <PaymentMethod
            key={paymentMethod.getName()}
            active={paymentData.name === paymentMethod.getName()}
            disabled={paymentMethods.length === 1}
            title={<paymentMethod.TitleComponent orderState={orderState} />}
            onActivate={() => onChangePaymentMethod(paymentMethod.getInitialData())}
          >
            <paymentMethod.Component
              /* @ts-expect-error polymorph */
              data={paymentData}
              orderState={orderState}
              errors={{}}
              onDataChange={(data) => {
                onChangePaymentMethod({ ...paymentData, ...data })
              }}
            />
          </PaymentMethod>
        ))}
      </div>

      <div className={styles.additionalFields}>
        {(['email', 'phone'] as const)
          .filter((key) => formData[key]?.value !== undefined)
          .map((key) => (
            <div key={key}>
              <v3.Input
                name={key}
                type="tel"
                label={t(`payform.${key}`, {
                  defaultValue: t(
                    `payform.formDefinitions.${formDefinitionI18nMap[key]}`,
                  ),
                })}
                onChange={(event) => {
                  const { value } = event.target;

                  onFormDataChange({
                    ...formData,
                    [key]: {
                      disabled: !!formData[key]?.disabled,
                      value: formatField(key, value),
                    },
                  })
                }}
                disabled={formData[key]?.disabled}
                value={formData[key]?.value}
                className={styles.textField}
                isError={!!formData[key]?.required && !formData[key]?.value}
                fullWidth
                required
              />
            </div>
          ))}

        {(['birthday'] as const)
          .filter((key) => formData[key]?.value !== undefined)
          .map((key) => (
            <div key={key}>
              <v3.Input
                name={key}
                type="date"
                label={t(`payform.${key}`, {
                  defaultValue: t(
                    `payform.formDefinitions.${formDefinitionI18nMap[key]}`,
                  ),
                })}
                onChange={(event) => {
                  const { value } = event.target;

                  onFormDataChange({
                    ...formData,
                    [key]: {
                      disabled: !!formData[key]?.disabled,
                      value,
                      required: !!formData[key]?.required,
                    },
                  })
                }}
                disabled={formData[key]?.disabled}
                value={formData[key]?.value}
                className={styles.textField}
                isPersistentPlaceholder
                isError={!isBirthdateValid(formData[key]?.value || '')}
                fullWidth
                required
              />
            </div>
          ))}
      </div>

      <Checkbox
        id="terms"
        name="terms"
        checked={formData.acceptTerms?.value === 'true'}
        onChange={() => {
          onFormDataChange({
            ...formData,
            acceptTerms: {
              disabled: !!formData.acceptTerms?.disabled,
              value: formData.acceptTerms?.value === 'true' ? 'false' : 'true',
              required: true,
            },
          });
        }}
        required
        label={
          <v4.Typography variant="Body/400">
            {t('payform.confirmation.agree')}{' '}
            <Link
              className={styles.link}
              target="_blank"
              rel="noopener noreferrer"
              href="https://ownrwallet.com/terms"
              tabIndex={-1}
            >
              {t('payform.confirmation.terms')}
            </Link>
          </v4.Typography>
        }
      />

      {(orderState?.status === 'error' || orderStateError) ? (
        <div className={styles.errorContainer}>
          <v4.Icon iconName="Attention_1" className={styles.errorIcon} />
          <v4.Typography variant="Body/400" className={styles.errorText}>{errorMessage}</v4.Typography>
        </div>
      ) : null}
    </div>
  );
};

export default OrderAuthorize;
