import { ThemeProvider } from '@acme/web-ui-kit';
import { Provider } from 'react-redux';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import FullPageLoader from 'src/components/FullPageLoader';
import Redirect from 'src/components/Redirect';

import './i18n/config';
import Order from './pages/Order';
import Sepa from './pages/Sepa';
import store from './redux';
import { initLogger } from './services/logger';

initLogger();

const router = createBrowserRouter([
  {
    path: '/:orderId',
    element: <Order />,
  },
  {
    path: 'sepa/:orderId',
    element: <Sepa />,
  },
  {
    path: 'sepa',
    element: <Redirect />,
  },
  {
    path: '*',
    element: <FullPageLoader />,
  },
]);

const App = () => (
  <Provider store={store}>
    <ThemeProvider>
      <RouterProvider router={router} />
    </ThemeProvider>
  </Provider>
);

export default App;
