import { memo } from 'react';
import { Typography } from '@acme/web-ui-kit';
import useTimer from 'src/hooks/useTimer';
import TimerIcon from 'src/assets/timer.svg';

import styles from './styles.module.scss';
import { TProps } from './Timer.types';

const Timer = ({ expireAt }: TProps) => {
  const timer = useTimer(expireAt);

  if (!timer) {
    return null;
  }

  return (
    <div className={styles.timerWrapper}>
      <img src={TimerIcon} className={styles.timerIcon} alt="Timer" />
      <Typography variant="H4Normal" className={styles.timer}>
        {timer}
      </Typography>
    </div>
  );
};

export default memo(Timer);
