import * as Sentry from "@sentry/react";

export class SentryLogger {
  private username: string | null = null;

  public static initSentry(dsn: string) {
    Sentry.init({
      dsn,
      integrations: [new Sentry.BrowserTracing()],
      tracesSampleRate: 1,
    });

  }

  private getScope() {
    const scope = new Sentry.Scope();

    scope.setUser(
      this.username
        ? {
            username: this.username,
          }
        : null,
    );
    return scope;
  }

  public setUser(username: string | null) {
    this.username = username;
  }

  /**
   * Логировать событие-сообщение
   */
  public logMessage(message: string, extraData?: Record<string, unknown>) {
    const scope = this.getScope();

    if (extraData) {
      scope.setExtra('data', extraData);
    }

    Sentry.captureMessage(message, scope);
  }

  /**
   * Логировать ошибку
   */
  public logException(e: Error, extraData?: Record<string, unknown>) {
    const scope = this.getScope();

    if (extraData) {
      scope.setExtra('data', extraData);
    }

    Sentry.captureException(e, scope);
  }

  public addBreadcrumb(message: string, data?: Record<string, unknown>) {
    const scope = this.getScope();

    scope.addBreadcrumb({ message, data, level: 'info' });
  }
}
