import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import ExpiredMessage from './ExpiredMessage';
import FailedMessage from './FailedMessage';
import SuccessMessage from './SuccessMessage';
import Header from './Header';
import { Props } from './Template.types';
import styles from './Template.module.scss';
import ErrorMessage from './ErrorMessage';

const Template = ({
  orderState,
  messageType,
  orderContent,
  actions,
  Layout,
}: Props) => {
  const { t } = useTranslation();

  const errorMessage = useMemo(() => {
    switch (messageType) {
      case 'EXPIRED': {
        return (
          <ExpiredMessage />
        );
      }
      case 'FAILED': {
        return (
          <FailedMessage />
        );
      }
      case 'SUCCESS': {
        return <SuccessMessage />;
      }
      default: {
        return null;
      }
    }
  }, [messageType]);

  if (messageType === 'UNKNOWN_ERROR') {
    return (
      <Layout>
        <div className={styles.wrapper}>
          <Header title={t('payform.secure_payment')} />

          <ErrorMessage />
        </div>
      </Layout>
    );
  }

  return (
    <Layout>
      <div className={styles.wrapper}>
        <Header
          title={t('payform.secure_payment')}
          expiresAt={messageType === null || messageType === 'ERROR' ? orderState?.expires_at : null}
        />

        {orderContent}

        {errorMessage}

        {actions}
      </div>
    </Layout>
  );
};

export default Template;
