import flagIcons from '@acme/flag-icons/images';

export const FLAG_ICONS = {
  EN: flagIcons.US,
  DE: flagIcons.DE,
  ES: flagIcons.ES,
  FR: flagIcons.FR,
  PT: flagIcons.PT,
  JA: flagIcons.JP,
  KO: flagIcons.KR,
  RU: flagIcons.RU,
  ZH: flagIcons.CN,
};
