import { FormDefinition } from 'src/types/order';
import { getAdditionalFields } from 'src/utils/helpers';
import Default from 'src/templates/Default';
import CryptoTabBrowser from 'src/templates/CryptoTabBrowser';
import CardStore from 'src/templates/CardStore';
import Bitfinex from 'src/templates/Bitfinex';
import { typedObjKeys } from 'src/utils/general';

import { OrderDetailsFormData, ORDER_DETAILS_FIELDS } from './Order.types';

function getFormDefinitionField(
  formDefinition: Partial<Record<string, FormDefinition>>,
  field: string,
) {
  return formDefinition[field]
    ? {
        value: formDefinition[field]?.defaultValue || '',
        disabled: !!formDefinition[field]?.viewOnly || formDefinition[field]?.section === 'details',
        required: formDefinition[field]?.required,
      }
    : formDefinition[field];
}

export function formatFormDefinition(formDefinition: string) {
  const parsedFormDefinition = {
    ...getAdditionalFields(
      formDefinition,
      'label',
      'details',
      [],
      ORDER_DETAILS_FIELDS,
    ),
    ...getAdditionalFields(
      formDefinition,
      'input',
      'contact',
      [],
      ORDER_DETAILS_FIELDS,
    ),
  };

  return Object.fromEntries(
    ORDER_DETAILS_FIELDS.map((field) => [
      field,
      getFormDefinitionField(parsedFormDefinition, field),
    ]),
  ) as OrderDetailsFormData;
}

export function getTemplate ({ formView, fixedView }: {
  formView?: string;
  fixedView?: string;
}) {
  switch (fixedView || formView) {
    case 'cardstore': {
      return CardStore;
    }
    case 'cryptotab-browser': {
      return CryptoTabBrowser;
    }
    case 'bitfinex': {
      return Bitfinex;
    }
    default: {
      return Default;
    }
  }
}

export function isValidDetailsData (formData: OrderDetailsFormData): boolean {
  return typedObjKeys(formData).every((key) => !formData[key]?.required || formData[key]?.value);
}
