import { memo, PropsWithChildren } from 'react';
import { Typography, Loader as WebUiLoader } from '@acme/web-ui-kit';
import cx from 'clsx';
import { useTranslation } from 'react-i18next';

import styles from './styles.module.scss';
import { TProps } from './Loader.types';

const Loader = memo(
  ({ loading, children, className }: PropsWithChildren<TProps>) => {
    const { t } = useTranslation();

    return (
      <div className={styles.wrapper}>
        {loading ? (
          <div className={cx(styles.progress, className)}>
            <WebUiLoader />
            <Typography variant='H5Normal' className={styles.progressTitle}>
              {t('payform.please_wait')}
            </Typography>
          </div>
        ) : children}
      </div>
    );
  },
);

export default Loader;
