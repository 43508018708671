import { ComponentProps } from 'src/models/PaymentMethod';

import { GooglePayPaymentData } from './GooglePay.types';

// eslint-disable-next-line no-empty-pattern
function GooglePay ({}: ComponentProps<GooglePayPaymentData>) {
  return null;
}

export default GooglePay;
