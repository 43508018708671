import Loader from 'src/components/Loader';

import styles from './styles.module.scss';

const FullPageLoader = () => (
  <Loader loading>
    <div className={styles.fillWindow} />
  </Loader>
);

export default FullPageLoader;
