import { useTranslation } from 'react-i18next';
import { Button } from '@acme/web-ui-kit';
import useSepaOrderState from 'src/hooks/useSepaOrderState';
import Print from 'src/assets/print.svg';
import downloadSepaPdf from 'src/api/methods/downloadSepaPdf';
import { SnackbarType } from 'src/types/snackbar';
import useSnackbar from 'src/hooks/useSnackbar';

import styles from './styles.module.scss';

const Actions = () => {
  const { t } = useTranslation();
  const { sepaOrderState, fetchStartSepaOrder } = useSepaOrderState();
  const { setSnackbarValue} = useSnackbar();

  if (!sepaOrderState) {
    return null;
  }

  const { url_on_success, url_on_failure, invoice_number, id } = sepaOrderState;
  const backButtonTitle =
    sepaOrderState.status === 'expired' ? t('sepa.back_to_portal') : t('back');

  const handleOnClick = () => {
    fetchStartSepaOrder(sepaOrderState.id)
      .then(() => {
        // eslint-disable-next-line promise/always-return
        if (url_on_success) {
          window.location.href = url_on_success;
        }
      })
      .catch(() => {
        if (url_on_failure) {
          window.location.href = url_on_failure;
        }
      });
  };

  const handleDownload = () => {
    downloadSepaPdf(id).then((response) => {
      // eslint-disable-next-line promise/always-return
      if (response.status !== 'OK') {
        throw new Error(response.error_code);
      }

      const linkSource = `data:application/pdf;base64,${response.pdf_file}`;
      const downloadLink = document.createElement('a');
      const fileName = `invoice_${invoice_number}.pdf`;

      downloadLink.href = linkSource;
      downloadLink.download = fileName;
      downloadLink.click();
    }).catch(() => {
      setSnackbarValue(t('errors.UNKNOWN_ERROR'), SnackbarType.Error);
    });
  };

  return (
    <div className={styles.actions}>
      {url_on_success ? (
        <a className={styles.link} href={url_on_success}>
          <Button variant="outlined" className={styles.button}>
            {backButtonTitle}
          </Button>
        </a>
      ) : null}

      {sepaOrderState.status !== 'expired' ? (
        <>
          <Button
            variant="outlined"
            className={styles.button}
            onClick={handleOnClick}
          >
            {t('sepa.i_paid')}
          </Button>
          <Button className={styles.button} onClick={handleDownload}>
            <div className={styles.printButtonContent}>
              <img className={styles.printIcon} src={Print} alt="" />
              {t('sepa.print_to_pdf')}
            </div>
          </Button>
        </>
      ) : null}
    </div>
  );
};

export default Actions;
