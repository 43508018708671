interface IWindow extends Window {
  _env_: Record<string, string>;
}

function getVariable(name: string): string {
  if (process.env.NODE_ENV === 'development') {
    return process.env[name] || '';
  }
  // eslint-disable-next-line no-underscore-dangle
  return (window as unknown as IWindow)._env_[name];
}

const config = {
  apiUrl: getVariable('REACT_APP_API_URL'),
  gatewayMerchantId: getVariable('REACT_APP_GATEWAY_MERCHANT_ID'),
  gateway: getVariable('REACT_APP_GATEWAY'),
  merchantId: getVariable('REACT_APP_MERCHANT_ID'),
  merchantName: getVariable('REACT_APP_MERCHANT_NAME'),
  environment: getVariable('REACT_APP_ENVIRONMENT'),
  sentryDSN: getVariable('REACT_APP_SENTRY_DSN'),
  appConsumer: getVariable('REACT_APP_CONSUMER'),
};

export default config;
