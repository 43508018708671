import { ExternalPubHttpApiProcessingValidateApplePayMerchantRequest } from '@acme/external-processing-api-client/dist/types';

import apiClient from '../client';
import { TWithoutRequestId } from '../types';

type TValidateMerchantRequest =
  TWithoutRequestId<ExternalPubHttpApiProcessingValidateApplePayMerchantRequest>;

export default async function validateMerchant(data: TValidateMerchantRequest) {
  return apiClient.processingValidateApplePayMerchant(data);
}
