import { useCallback } from 'react';
import { Snackbar as WebUiSnackbar } from '@acme/web-ui-kit';
import useSnackbar from 'src/hooks/useSnackbar';
import { SnackbarType } from 'src/types/snackbar';

import { SnackBarVariant } from './Snackbar.types';

const snackbarVariantByType: Map<SnackbarType, SnackBarVariant> =
  new Map([
    [SnackbarType.Default, 'info'],
    [SnackbarType.Success, 'success'],
    [SnackbarType.Error, 'danger'],
  ]);

const Snackbar = () => {
  const { snackbarValue, setSnackbarValue, snackbarType } = useSnackbar();

  const onClose = useCallback(() => {
    setSnackbarValue('');
  }, [setSnackbarValue]);

  return (
    <WebUiSnackbar
      visible={Boolean(snackbarValue)}
      variant={snackbarVariantByType.get(snackbarType) as SnackBarVariant}
      onClose={onClose}
      autoHideDuration={6000}
    >
      {snackbarValue}
    </WebUiSnackbar>
  );
};

export default Snackbar;
